import { useState } from 'react';
import { motion } from 'framer-motion';

const images = [
  './asset/img-0.jpg',
  './asset/img-1.jpg',
  './asset/img-2.jpg',
  './asset/img-4.jpg',
];

export default function HeroImageGrid() {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (src) => {
    setSelectedImage(src);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="relative p-4 lg:w-1/2 w-full my-11">
      <div className="absolute top-[-20px] left-1/2 transform -translate-x-1/2 animate-bounce">
        👈 Click on an image!
      </div>
      <div className="grid grid-cols-2 gap-4 cursor-pointer ">
        {images.map((src, index) => (
          <motion.div
            key={index}
            className="overflow-hidden rounded-lg h-64"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
            onClick={() => openModal(src)} // Open modal on image click
          >
            <img
              src={src}
              alt={`Proof ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </motion.div>
        ))}
      </div>

      {/* Modal for zoomed-in image */}
      {selectedImage && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={closeModal}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}>
          <motion.img
            src={selectedImage}
            alt="Zoomed Image"
            className="max-w-full max-h-[80vh] object-contain"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
      )}
    </div>
  );
}
