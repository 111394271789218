import React, { useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa'; // Optional for an icon

import constantObj from '../constant/constant';

const AttentionMessage = ({ message }) => {
  const [visible, setVisible] = useState(true);

  return (
    <>
      {visible && (
        <a
          href={constantObj.ADMIN}
          className="relative bg-green-500 border-l-4 border-green-500 text-yellow-900 p-4 my-4 rounded-md animate-pulse shadow-lg">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <FaExclamationCircle className="text-yellow-700 text-xl mr-2" />
              <span className="font-semibold text-slate-50">{message}</span>
            </div>
          </div>
        </a>
      )}
    </>
  );
};

export default AttentionMessage;
