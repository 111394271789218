import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import constantObj from '../constant/constant';
import moment from 'moment'; 

const DeclinedPaymentsTable = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    const fetchPayments = async () => {
      const token = Cookies.get('token');
      try {
        const response = await axios.get(
          `${constantObj.API_URL_PROD}/unbeatabletechEa.API/admin/payments/declined`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.declinedPayments) {
          setPayments(response.data.declinedPayments);
          if (response.data.declinedPayments.length === 0) {
            setErrorMessage('No declined payments found.');
          }
        } else {
          setErrorMessage('Unexpected response structure.');
        }
      } catch (error) {
        console.error('Error fetching declined payments', error.response);
        if (error.response) {
          if (error.response.status === 400) {
            setErrorMessage(error.response.data.message || 'Bad Request.');
          } else if (
            error.response.status === 401 ||
            error.response.data.message.includes('jwt')
          ) {
            setErrorMessage('Unauthorized access. Redirecting to login...');
            setTimeout(() => {
              window.location.href = '/admin/login';
            }, 2000);
          } else if (error.response.status === 404) {
            setErrorMessage(
              error.response.data.message || 'Payments not found.'
            );
          } else if (error.response.status === 500) {
            setErrorMessage('Server error. Please try again later.');
          } else {
            setErrorMessage('An unexpected error occurred. Please try again.');
          }
        } else {
          setErrorMessage('Network error. Please check your connection.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, []);

  const openModal = (screenshotUrl) => {
    setModalContent(screenshotUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  return (
    <div className="overflow-x-auto">
      {loading ? (
        <p className="text-center text-gray-500">
          Loading declined payments...
        </p>
      ) : errorMessage ? (
        <p className="text-center text-red-500">{errorMessage}</p>
      ) : (
        <>
          <table className="min-w-full bg-gray-100 rounded-lg shadow-md">
            <thead className="bg-gray-800">
              <tr>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  #
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Date
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Depositor
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Amount
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Recipient
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Plan
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Screenshot
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {payments.map((payment, index) => (
                <tr
                  key={payment._id}
                  className="hover:bg-gray-50 transition-colors duration-200">
                  <td className="border px-4 py-3 text-gray-700">
                    {index + 1}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    {moment(payment?.createdAt).format(
                      'MMMM Do YYYY, h:mm:ss a'
                    )}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    {payment.depositor}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    ${payment.amount}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    {payment.recipient}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    {payment.plan}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    <span
                      className="text-blue-500 cursor-pointer"
                      onClick={() =>
                        openModal(
                          `${constantObj.API_URL_PROD}/unbeatabletechEa.API/images/${payment.screenshot}`
                        )
                      }>
                      View Screenshot
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {isModalOpen && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div
            className="bg-white p-6 rounded-lg shadow-lg relative"
            style={{ width: '80%', maxWidth: '800px', height: '90vh', maxHeight: '600px', overflowY: 'auto' }}>
        
            <button
              className="text-red-500 absolute top-2 right-2"
              onClick={closeModal}>
              Close
            </button>
        
            <img
              src={modalContent}
              alt="Screenshot"
              className="max-w-full max-h-full object-contain"
              onError={() => setModalContent('')}
            />
        
            {modalContent && !modalContent.includes('http') && (
              <p className="text-red-500 mt-4">Error loading image.</p>
            )}
          </div>
        </div>
        
          )}
        </>
      )}
    </div>
  );
};

export default DeclinedPaymentsTable;
