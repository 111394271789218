

import { useState } from 'react';
import { motion } from 'framer-motion';

const images = [
  './asset/proof/proof-4.jpeg',
  './asset/proof/proof-1.jpeg',
  './asset/proof/proof-2.jpeg',
  './asset/proof/proof-3.jpeg',
];

export default function ImageGrid() {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (src) => {
    setSelectedImage(src);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="relative p-4 w-full my-16 mx-auto">
      <h1 className="px-15 mx-auto text-center text-xl sm:text-2xl font-roboto font-bold py-6">
        Trust is a commodity we can offer you
      </h1>
      <div className="absolute top-[-20px] left-1/2 transform -translate-x-1/2 animate-bounce">
        Click on an image to enlarge! 👇👇
      </div>

      {/* Image container with horizontal scrolling */}
      <div className="flex mx-auto justify-center items-center overflow-x-auto gap-6">
        {images.map((src, index) => (
          <motion.div
            key={index}
            className="flex-shrink-0 w-64 h-64 overflow-hidden rounded-lg"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
            onClick={() => openModal(src)} // Open modal on image click
          >
            <img
              src={src}
              alt={`Proof ${index + 1}`}
              className="w-full h-full object-cover"
            />
          </motion.div>
        ))}
      </div>

      {/* Modal for zoomed-in image */}
      {selectedImage && (
        <motion.div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={closeModal}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}>
          <motion.img
            src={selectedImage}
            alt="Zoomed Image"
            className="max-w-full max-h-[80vh] object-contain"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.3 }}
          />
        </motion.div>
      )}
    </div>
  );
}
