

import { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'; // Remove Pagination from here
import 'swiper/css';
import 'swiper/css/navigation';
// Remove 'swiper/css/pagination'; since we won't use pagination

const terms = [
  { label: 'Members', maxValue: 10000 },
  { label: 'Total Profit', maxValue: 500000 },
  { label: 'Daily Trades', maxValue: 200 },
  { label: 'Success Rate', maxValue: 95 }, // This one will have a percentage sign
  { label: 'Volume Traded', maxValue: 1000000 },
];

const formatNumber = (num, isPercentage = false) => {
  if (isPercentage) return num + '%'; // Append percentage sign for percentage values
  if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M';
  if (num >= 1000) return (num / 1000).toFixed(1) + 'K';
  return num.toString();
};

export default function LineCounter() {
  const [counts, setCounts] = useState(terms.map(() => 0));
  const intervalRefs = useRef([]); // To store interval IDs

  useEffect(() => {
    const counters = terms.map((term) => term.maxValue);

    counters.forEach((target, index) => {
      let current = 0;
      intervalRefs.current[index] = setInterval(() => {
        if (current < target) {
          setCounts((prevCounts) => {
            const updatedCounts = [...prevCounts];
            updatedCounts[index] = Math.min(current + 100, target);
            return updatedCounts;
          });
          current += 100;
        } else {
          clearInterval(intervalRefs.current[index]);
        }
      }, 50);
    });

    // Clear intervals on unmount
    return () => {
      intervalRefs.current.forEach(clearInterval);
    };
  }, []);

  return (
    <div className="relative w-full min-h-20 mt-12 bg-black px-4 py-4 sm:px-6 md:px-8 lg:px-12 flex items-center overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-gray-700 via-gray-800 to-black opacity-10"></div>

      <Swiper
        modules={[Navigation]} // Only include Navigation module
        spaceBetween={20}
        slidesPerView={2}
        grabCursor={true}
        navigation
        // Removed pagination prop
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 40,
          },
        }}
        className="w-full">
        {terms.map((term, index) => (
          <SwiperSlide key={index}>
            <motion.div
              className="flex flex-col items-center text-white transform transition-transform duration-300 hover:scale-110 text-center"
              whileHover={{ scale: 1.05 }}>
              <motion.span
                className="text-2xl sm:text-3xl md:text-4xl font-bold"
                animate={{ opacity: counts[index] ? 1 : 0 }}
                transition={{ duration: 0.5 }}>
                {formatNumber(counts[index], term.label === 'Success Rate')}{' '}
                {/* Pass true if it's success rate */}
              </motion.span>
              <motion.span
                className="text-xs sm:text-sm md:text-base uppercase tracking-widest"
                animate={{ opacity: counts[index] ? 1 : 0 }}
                transition={{ duration: 0.5 }}>
                {term.label}
              </motion.span>
            </motion.div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
