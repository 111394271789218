import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import LineCounter from './line';
import TradingChart from './tradingCahrt';

import constantObj from '../constant/constant';
import HeroImageGrid from './hero_img';

const images = [
  '../asset/hero.jpg',
  '../asset/hero-2.jpg',
  '../asset/hero-3.jpg',
  '../asset/hero-4.jpg',
];

const forexTexts = [
  {
    text1: 'Trade Smarter, Not Harder',
    text2: 'Unlock Your Trading Potential',
  },
  {
    text1: 'Experience The Power Of UnbeatableFx Robot',
    text2: 'Be one of the successful traders today',
  },
  {
    text1: 'Maximize your profit with our trading bot',
    text2: 'Stay Ahead of the Market Trends',
  },
  {
    text1: 'Your Journey to Financial Freedom',
    text2: 'Start Trading Forex with Us',
  },
  {
    text1: '24/5 Trading Flexibility',
    text2: 'Trade Anytime, Anywhere',
  },
];

export default function HeroSection() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section
      className="relative w-full min-h-screen flex py-24 sm:py-32 flex-col items-center justify-center overflow-hidden"
      aria-label="Forex Trading Hero Section">
      <div className="absolute inset-0">
        {images.map((image, index) => (
          <motion.div
            key={index}
            className={`absolute inset-0 bg-cover bg-center ${
              index === currentIndex ? 'block' : 'hidden'
            }`}
            style={{ backgroundImage: `url(${image})` }}
            initial={{ opacity: 0, scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 1, ease: 'easeInOut' }}
            aria-hidden="true"
          />
        ))}
        <div className="absolute inset-0 bg-black opacity-50" />
      </div>
      <div className="relative z-10 text-center text-white p-2">
        {/* Text for current slide */}
        <motion.h1
          key={`text1-${currentIndex}`}
          initial={{ x: '-100vw', opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '100vw', opacity: 0 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          className="text-4xl md:text-6xl font-bold mb-4 font-roboto"
          aria-label="Main slogan">
          {forexTexts[currentIndex].text1}
        </motion.h1>
        <motion.p
          key={`text2-${currentIndex}`}
          initial={{ x: 10000, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: '-100vw', opacity: 0 }}
          transition={{ duration: 1, ease: 'easeInOut' }}
          className="text-xl md:text-2xl mb-11 text-gray-300 font-montserrat"
          aria-label="Supporting text">
          {forexTexts[currentIndex].text2}
        </motion.p>
        <a
          href={constantObj.TELEGRAM}
          className="bg-green-500 mt-11 text-white uppercase font-semibold rounded-md py-3 px-6 transition-transform transform hover:scale-105"
          aria-label="Join now button">
          Join Now
        </a>
      </div>
      {/* Slider Indicator */}
      <div className="relative z-10 flex space-x-2 my-11">
        {images.map((_, index) => (
          <div
            key={index}
            className={`w-10 h-1 rounded-sm cursor-pointer ${
              index === currentIndex ? 'bg-green-500' : 'bg-gray-400'
            }`}
            onClick={() => setCurrentIndex(index)}
            aria-label={`Slide ${index + 1} indicator`}
          />
        ))}
      </div>
      <LineCounter />

      <TradingSection />
    </section>
  );
}

function TradingSection() {
  return (
    <motion.div
      className="text-slate-50 mt-32 flex lg:flex-row flex-col items-center z-30 py-11 px-8 mx-auto gap-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}>
      <HeroImageGrid />
      {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:w-1/2 w-full ">
        <TradingChart />
        <TradingChart />
        <TradingChart />
        <TradingChart />
      </div> */}

      <motion.div
        className="lg:w-1/2 w-full mx-auto flex flex-col gap-4 justify-center text-center lg:text-left"
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.7, delay: 0.4 }}>
        <motion.h1
          className="text-gray-100 font-poppins font-bold text-2xl sm:text-3xl lg:text-4xl uppercase"
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}>
          THE TRADING ROBOT STRATEGY
        </motion.h1>
        <motion.p
          className="text-gray-400 text-base text-left px-4 sm:text-xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}>
          Robot built-in market scan and create order detect, and sensitive
          strategies optimizing. The robot incorporates some very advance
          trading algorithms. It does not use dangerous strategies, does not use
          martingale and hedge. This robot trade with 4 strategy [ Price Action,
          Trend With Pivot Point, Candlestick, also scalping methods ] Every
          pairs have different set file.
        </motion.p>
        <motion.span
          className="flex flex-col sm:flex-row items-center gap-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.7 }}>
          <a
            href={constantObj.TELEGRAM}
            className="bg-green-500 sm:w-60 w-4/5 px-4 py-3 rounded-md text-base font-roboto text-slate-50 hover:bg-green-600 transition duration-300"
            aria-label="Try demo button">
            Try Demo
          </a>
          <a
            href={constantObj.TELEGRAM}
            className="bg-transparent sm:w-60 w-4/5 border-2 border-green-500 box-border px-4 py-3 rounded-md text-base font-roboto text-slate-50 hover:border-green-600 hover:text-green-500 transition duration-300"
            aria-label="Start trading button">
            Start Trading
          </a>
        </motion.span>
      </motion.div>
    </motion.div>
  );
}
