const constantObj = {
  API_URL_PROD: 'https://unbeatable-admin.onrender.com',
  API_URL_DEV: 'http://localhost:5000',
  TIKTOK: 'https://www.tiktok.com/@unbeatable_ea?_t=8qIHKDihpSf&_r=1',
  TELEGRAM: 'https://t.me/+VlT6-5JUdBBjMTA0',
  INSTAGRAM:
    'https://www.instagram.com/unbeatable_tech_guy/profilecard/?igsh=eTE0NmQwZm1seWYw',
  FACEBOOK: 'https://www.facebook.com/share/QkdLTK3HDvy1WpPH/?mibextid=qi2Omg',
  FACEBOOK_CHANNEL: 'https://www.facebook.com/sourcepro1?mibextid=ZbWKwL',
  BTC_WALLET: '1Myb5CCApr8Eodh2AmZpEMv5uKb2aMRsRR',
  USDT_WALLET: 'TDBxPmYRrZdo5QjKjqcu5N3ysLnz7KweBj',
  ADMIN: 'https://t.me/Techintrodev',
};

export default constantObj;
