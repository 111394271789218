import { FaCheck } from 'react-icons/fa';
import AboutUs from './about';

import CustomerReviews from './review';
import PricingSection from './pricing';
import ProofOfWorkSection from './proof';
import ContactUs from './form';
import FAQSection from './faq';
import ImageGrid from './img';
import constantObj from '../constant/constant';

export default function MainBody() {
  return (
    <main className="bg-gray-50 text-slate-950 py-11 ">
      <AboutUs />

      <CustomerReviews />
      <ProofOfWorkSection />

      <ImageGrid />

      <h1 className="text-center font-bold font-poppins text-2xl mt-11">
        AVAILABLE ROBOT
      </h1>
      <div className=" lg:grid w-full px-6 my-11 mx-auto sm:w-4/5 flex flex-col lg:grid-cols-2 gap-8">
        <Card
          list={['Easy To Install', 'Robot work 24 hours and 5 days']}
          heading={'Metatrader 4 Robot'}
          btnTxt={'Pricing Overview'}
          imgSrc={'../asset/img.png'}
          gridspan={'col-span-2'}
        />
        <Card
          list={[
            '99% wining rate',
            'Unlimited License Available',
            'It work perfectly on any broker of your choice',
          ]}
          heading={'Metatrader 5 Robot'}
          btnTxt={'Pricing Overview'}
        />
        <Card
          list={[
            'Robot Work Smoothly On Tradelocker,  Matchtrader, Dxtrade.',
            'It pass evaluation stage faster. ',
            'It has Equity Protection and built with accurate risk management.',
          ]}
          heading={'High Frequency Trading Bot'}
          btnTxt={'Raw Pricing Benefits'}
        />
      </div>
      <PricingSection />

      <FAQSection />
      <ContactUs />
    </main>
  );
}

function Card({ heading, list, imgSrc, btnTxt, gridspan }) {
  return (
    <div
      className={`relative bg-gray-100 rounded-xl overflow-hidden lg:flex lg:flex-row lg:gap-8 w-full  mx-auto py-11 px-8 ${
        gridspan || ''
      }`}>
      <div className="absolute inset-0 bg-black opacity-10"></div>
      <div className="relative flex flex-col lg:flex-row items-start gap-6 lg:gap-8 z-10">
        <div className="flex flex-col w-4/5 items-start justify-start gap-6">
          <h2 className="text-2xl lg:text-4xl font-bold font-poppins text-gray-800">
            {heading}
          </h2>

          <ul className="flex flex-col gap-4">
            {list.map((item, index) => (
              <li
                key={index}
                className="flex items-center text-sm gap-4 w-full">
                <div className="  bg-green-500 text-white flex items-center justify-center px-2 py-2  w-4 h-4 rounded-full">
                  {/* <FaCheck className="text-sm" /> */}✔
                </div>

                <p className="w-4/5">{item}</p>
              </li>
            ))}
          </ul>

          <a
            href={constantObj.TELEGRAM}
            className="bg-green-500 text-white border border-green-500 rounded-md text-base capitalize font-bold px-4 py-2 w-60 text-center font-roboto transition-colors duration-300 hover:bg-green-600 hover:text-gray-900">
            {btnTxt}
          </a>
        </div>
        {imgSrc && (
          <div className="flex-shrink-0 w-full sm:w-1/2">
            <img
              src={imgSrc}
              alt="evidence"
              className="sm:w-4/5 w-full mx-auto h-auto rounded-lg shadow-md"
            />
          </div>
        )}
      </div>
    </div>
  );
}
