import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import constantObj from '../constant/constant';

export default function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);

    // Display the popup message on form submission
    setShowPopup(true);

    // Reset form fields
    setFormData({ name: '', email: '', message: '' });

    // Hide popup after 3 seconds
    setTimeout(() => {
      setShowPopup(false);
    }, 3000);
  };

  return (
    <section className="bg-gray-50 py-16 w-full">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between max-w-6xl px-4">
        {/* Left - Form Section */}
        <motion.div
          className="md:w-1/2 w-full p-8"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}>
          <h2 className="text-4xl font-bold text-center md:text-left mb-8 text-gray-800">
            Contact Our Support Team
          </h2>
          <p className="text-lg text-center md:text-left mb-8 text-gray-600">
            We’re here to assist you. Fill out the form and we’ll get back to
            you as soon as possible.
          </p>
          <motion.form
            onSubmit={handleSubmit}
            className="bg-white w-full p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
            whileHover={{ scale: 1.02 }}>
            <div className="mb-6">
              <label
                className="block text-sm font-semibold mb-2 text-gray-700"
                htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-sm font-semibold mb-2 text-gray-700"
                htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-sm font-semibold mb-2 text-gray-700"
                htmlFor="message">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                rows="5"
                className="w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-600"
              />
            </div>
            <motion.a
              type="submit"
              href={constantObj.ADMIN}
              className="w-full block bg-green-600 text-center text-white py-4 rounded-lg shadow-lg hover:bg-green-700 transition duration-200 transform hover:scale-105"
              whileHover={{ scale: 1.05 }}>
              Send Message
            </motion.a>
          </motion.form>
        </motion.div>

        {/* Right - Customer Support Quote Section */}
        <motion.div
          className="md:w-1/2 w-full p-8 mt-10 md:mt-0"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}>
          <div className="bg-green-100 p-8 rounded-xl shadow-md">
            <p className="text-lg italic font-semibold text-gray-700">
              "Our support team is dedicated to ensuring you have the best
              experience. Don’t hesitate to reach out to us!"
            </p>
            <a
              href={constantObj.ADMIN}
              className="mt-4 text-right text-sm text-green-600 font-semibold">
              - UnbeatableFx Robot Support Team 😊
            </a>
          </div>
        </motion.div>
      </div>

      {/* Popup Message */}
      <AnimatePresence>
        {showPopup && (
          <motion.div
            className="fixed top-16 left-1/2 transform -translate-x-1/2 bg-green-600 text-white px-6 py-4 rounded-lg shadow-lg"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5 }}>
            <p>Thank you! Your message has been sent successfully.</p>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
}
