import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'; // Import js-cookie for cookie management
import constantObj from '../constant/constant';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // State for loading
  const [error, setError] = useState(''); // State for error messages
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when login starts
    setError(''); // Reset error message

    try {
      const response = await axios.post(
        `${constantObj.API_URL_PROD}/unbeatabletechEa.API/admin/login`,
        { email, password }
      );

      console.log(response);
      Cookies.set('token', response.data.token, { path: '/' }); // Store token in cookie using js-cookie
      navigate('/admin/dashboard'); // Redirect to dashboard after login
    } catch (error) {
      console.error('Login failed', error.response);
      setError('Login failed. Please check your credentials.'); // Set error message
    } finally {
      setLoading(false); // Set loading to false after request completes
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <form
        onSubmit={handleLogin}
        className="bg-white p-8 rounded shadow-md w-full max-w-sm">
        <h2 className="text-2xl font-bold mb-6 text-center text-slate-900">
          Admin Login
        </h2>

        {/* Email Input */}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="block w-full mb-4 p-2 border text-slate-900 border-gray-300 rounded transition focus:outline-none focus:ring focus:ring-blue-500"
          required
        />

        {/* Password Input */}
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="block w-full mb-6 text-slate-900 p-2 border border-gray-300 rounded transition focus:outline-none focus:ring focus:ring-blue-500"
          required
        />

        {/* Loading Button */}
        <button
          type="submit"
          className={`w-full bg-blue-500 text-white py-2 rounded transition ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={loading} // Disable button during loading
        >
          {loading ? 'Logging in...' : 'Login'}
        </button>

        {/* Error Message */}
        {error && (
          <p className="text-red-500 text-sm mt-4 text-center">{error}</p>
        )}
      </form>
    </div>
  );
};

export default Login;
