import Footer from '../components/footer';
import Header from '../components/header';
import HeroSection from '../components/hero_section';
import MainBody from '../components/main';

export default function HomePage() {
  return (
    <div className="relative">
      <Header />
      <HeroSection />
      <MainBody />
      <Footer />
    </div>
  );
}
