import { useState } from 'react';
import { animate, motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const proofData = {
  Prom: [
    {
      id: 1,
      type: 'video',
      media: './asset/prom/replace-2.mp4',
      description: 'FTMO PAYOUT ',
    },
    {
      id: 2,
      type: 'video',
      media: './asset/prom/replace-3.mp4',
      description: '$100K TIGER FUNDED FIRM ',
    },
    {
      id: 3,
      type: 'image',
      media: './asset/prom/prom-3.jpg',
      description: 'TRADERLOCKER HFT BOT',
      orientation: 'landscape',
    },
    {
      id: 5,
      type: 'images',
      images: [
        { img: './asset/prom/phase-1.jpg', level: 'Phase 1 Passed ' },
        { img: './asset/prom/phase-2.jpg', level: 'Phase 2 Passed ' },
      ],
      description: 'PROP FIRM PASSER BOT',
    },
  ],
  Live: [
    {
      id: 1,
      type: 'image',
      media: './asset/live/replace-0.jpg',
      description: '$1,060 DAILY PROFITS ',
    },
    {
      id: 2,
      type: 'video',
      media: './asset/live/replace-1.mp4',
      description: 'MT4 LIVE TRADING',
      orientation: 'landscape',
    },
    {
      id: 3,
      type: 'video',
      media: './asset/live/tel-1.mp4',
      description: 'MT5 LIVE TRADING ',
      orientation: 'landscape',
    },
    {
      id: 4,
      type: 'video',
      media: './asset/live/tel-2.mp4',
      description: 'PROFITS WITHDRAWAL ',
      orientation: 'landscape',
    },
    // {
    //   id: 4,
    //   type: 'video',
    //   media: './asset/live/tel-3.mp4',
    //   description: 'Live Account Performance ',
    //   orientation: 'landscape',
    // },
    {
      id: 5,
      type: 'images',
      images: [
        { img: './asset/live/start.jpg', level: 'start' },
        { img: './asset/live/process.jpeg', level: 'processing' },
        { img: './asset/live/result.jpeg', level: 'Results' },
      ],
      description: 'ROBOT PERFORMING WONDERFUL',
    },
    // {
    //   id: 3,
    //   type: 'image',
    //   media: './asset/hero-4.jpg',
    //   description: 'Live Account Performance - Month 3',
    // },
    // {
    //   id: 4,
    //   type: 'image',
    //   media: './asset/hero-5.jpg',
    //   description: 'Live Account Performance - Month 4',
    // },
  ],
};

export default function ProofOfWorkSection() {
  const [selectedAccount, setSelectedAccount] = useState('Live');
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeProof, setActiveProof] = useState(null);

  const toggleAccount = (account) => {
    setSelectedAccount(account);
  };

  const openModal = (proof) => {
    setActiveProof(proof);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setActiveProof(null);
  };

  return (
    <section className="bg-gray-950 py-12 px-6 text-white">
      <h2 className="text-4xl font-bold text-center mb-8">Proof of Work</h2>
      <div className="flex justify-center mb-6">
        <button
          onClick={() => toggleAccount('Live')}
          className={`px-4 py-2 ${
            selectedAccount === 'Live' ? 'bg-green-500' : 'bg-gray-600'
          } rounded-lg`}>
          Live Account Results
        </button>
        <button
          onClick={() => toggleAccount('Prom')}
          className={`px-4 py-2 ${
            selectedAccount === 'Prom' ? 'bg-green-500' : 'bg-gray-600'
          } rounded-lg ml-4`}>
          Prop Firm Passed
        </button>
      </div>

      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
        navigation>
        {proofData[selectedAccount].map((proof) => (
          <SwiperSlide key={proof.id}>
            <motion.div
              className="bg-gray-700 rounded-lg p-6 cursor-pointer"
              whileHover={{ scale: 1.05 }}
              onClick={() => openModal(proof)}>
              {proof.type === 'images' ? (
                <img
                  src={proof.images[1].img} // Display the first image as a placeholder
                  alt={proof.description}
                  className="w-full h-48 object-cover rounded-lg mb-4"
                />
              ) : proof.type === 'image' ? (
                <img
                  src={proof.media}
                  alt={proof.description}
                  className="w-full h-48 object-cover rounded-lg mb-4"
                />
              ) : (
                <video
                  src={proof.media}
                  controls
                  muted
                  className="rounded-lg mb-4 w-full h-48"
                  style={{ objectFit: 'cover' }}
                />
              )}
              <h3 className="text-lg font-semibold text-center">
                {proof.description}
              </h3>
            </motion.div>
          </SwiperSlide>
        ))}
      </Swiper>

      {isModalOpen && activeProof && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div
            className="fixed inset-0 bg-black opacity-70"
            onClick={closeModal}></div>
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            className="bg-gray-900 rounded-lg p-4 z-10 max-w-lg w-full">
            {activeProof.images ? (
              <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={10}
                navigation
                // pagination={{ clickable: true }}
              >
                {activeProof.images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={image.img}
                      alt={`Slide ${index + 1}`}
                      className="max-w-full max-h-[80vh] mx-auto" // Limit height to 80% of viewport
                      style={{ objectFit: 'contain' }}
                    />
                    <motion.p
                      initial={{ x: -1000 }}
                      animate={{ x: 0 }}
                      className="font-poppins py-2 capitalize flex items-center justify-center text-xl mx-auto ">
                      {image.level}
                    </motion.p>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : activeProof.type === 'image' ? (
              <img
                src={activeProof.media}
                alt={activeProof.description}
                className="max-w-full max-h-[80vh] mx-auto"
                style={{ objectFit: 'contain' }}
              />
            ) : (
              <video
                src={activeProof.media}
                controls
                muted
                className="max-w-full max-h-[80vh] mx-auto"
                style={{ objectFit: 'contain' }}
              />
            )}
            <h3 className="text-lg font-semibold text-center mt-2">
              {activeProof.description}
            </h3>
            <button
              className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
              onClick={closeModal}>
              Close
            </button>
          </motion.div>
        </div>
      )}
    </section>
  );
}
