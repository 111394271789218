import { motion } from 'framer-motion';
export default function Logo() {
  return (
    <div className="flex  items-center font-montserrat text-2xl ">
      <motion.p
        animate={{ x: 0 }}
        initial={{ x: -200 }}
        transition={{
          duration: 0.5,
          ease: [0.25, 0.1, 0.25, 1],
        }}>
        UnbeatableFx
      </motion.p>
      <motion.p
        animate={{ y: 0 }}
        initial={{ y: -200 }}
        transition={{
          duration: 0.5,
          ease: [0.25, 0.1, 0.25, 1],
        }}
        className="text-green-500 italic font-roboto">
        Robot
      </motion.p>
    </div>
  );
}
