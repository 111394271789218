import { useState } from 'react';
import { motion } from 'framer-motion';
import Logo from './logo';
import constantObj from '../constant/constant';

const LIST = [
  { text: 'Home', link: '/' },
  { text: 'About', link: '#about' },
  { text: 'Contact', link: '#contact' },
  { text: 'Pricing', link: '#pricing' },
];

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="sticky top-0 z-50 w-full py-6 px-6 bg-gray-900 text-slate-50 flex justify-between items-center">
      {/* Logo with hover animation */}
      <motion.div
        whileHover={{
          scale: 1.1,
          transition: { duration: 0.5 },
        }}>
        <Logo />
      </motion.div>

      {/* Mobile Hamburger Menu */}
      <div className="lg:hidden">
        <motion.button
          onClick={() => setIsOpen(!isOpen)}
          className="text-slate-50"
          whileTap={{ scale: 0.9 }}
          whileHover={{ rotate: 180, transition: { duration: 0.3 } }}>
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </motion.button>
      </div>

      {/* Desktop Navigation */}
      <nav className="hidden lg:flex w-4/5 justify-between items-center">
        <List list={LIST} />
        <motion.a
          whileHover={{ scale: 1.2, transition: { duration: 1 } }}
          whileTap={{ scale: 0.9 }}
          className="font-montserrat uppercase bg-blue-500 outline-none rounded-full py-2 px-4 text-sm"
          href={constantObj.TELEGRAM}>
          Join Telegram
        </motion.a>
      </nav>

      {/* Mobile Dropdown Menu */}
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          transition={{ duration: 0.5 }}
          className="lg:hidden w-full absolute top-20 left-0 bg-gray-800 text-slate-50 py-4 px-6">
          <ul className="flex flex-col gap-6">
            {LIST.map((item, index) => (
              <motion.li
                key={index}
                whileHover={{ x: 10, transition: { duration: 0.3 } }}
                className="cursor-pointer text-lg hover:text-green-300"
                onClick={() => setIsOpen(false)}>
                <a href={item.link}>{item.text}</a>
              </motion.li>
            ))}
          </ul>
        </motion.div>
      )}
    </header>
  );
}

function List({ list }) {
  return (
    <ul className="flex mx-auto items-center justify-between gap-8">
      {list.map((item, index) => (
        <motion.li
          key={index}
          whileHover={{
            y: -10,
            borderBottomWidth: '4px',
            borderBottomColor: '#2E7D32',
            transition: { duration: 0.2 },
          }}
          className="cursor-pointer border-b-4 border-transparent hover:text-green-300"
          role="listitem">
          <a href={item.link}>{item.text}</a>
        </motion.li>
      ))}
    </ul>
  );
}
