import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import constantObj from '../constant/constant';

const CompletedPaymentsTable = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true); // To track the loading state
  const [errorMessage, setErrorMessage] = useState(''); // To handle error messages
  const [isModalOpen, setIsModalOpen] = useState(false); // To track modal open state
  const [modalContent, setModalContent] = useState(''); // To store the screenshot URL

  useEffect(() => {
    const fetchPayments = async () => {
      const token = Cookies.get('token');

      try {
        const response = await axios.get(
          `${constantObj.API_URL_PROD}/unbeatabletechEa.API/admin/payments/completed`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response);
        setPayments(response.data.completedPayments);
        if (response.data.completedPayments.length === 0) {
          setErrorMessage('No completed payments found.');
        }
      } catch (error) {
        console.error('Error fetching completed payments', error.response);

        if (error.response) {
          if (error.response.status === 400) {
            setErrorMessage(error.response.data.message || 'Bad Request.');
          } else if (
            error.response.status === 401 ||
            error.response.data.message.includes('jwt')
          ) {
            setErrorMessage('Unauthorized access. Redirecting to login...');
            setTimeout(() => {
              window.location.href = '/admin/login';
            }, 2000);
          } else if (error.response.status === 404) {
            setErrorMessage(
              error.response.data.message || 'Payments not found.'
            );
          } else if (error.response.status === 500) {
            setErrorMessage('Server error. Please try again later.');
          } else {
            setErrorMessage('An unexpected error occurred. Please try again.');
          }
        } else {
          setErrorMessage('Network error. Please check your connection.');
        }
      } finally {
        setLoading(false);
      }
    };
    fetchPayments();
  }, []);

  // Function to handle opening the modal and displaying the screenshot
  const openModal = (screenshotUrl) => {
    setModalContent(screenshotUrl);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  return (
    <div className="overflow-x-auto">
      {loading ? (
        <p className="text-center text-gray-500">Loading payments...</p>
      ) : errorMessage ? (
        <p className="text-center text-red-500">{errorMessage}</p>
      ) : (
        <>
          <table className="min-w-full bg-gray-100 rounded-lg shadow-md">
            <thead className="bg-gray-800">
              <tr>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  #
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Depositor
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Amount
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Recipient
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Plan
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Date
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Screenshot
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {payments.map((payment, index) => (
                <tr
                  key={payment._id}
                  className="hover:bg-gray-50 transition-colors duration-200">
                  {/* Serial Number */}
                  <td className="border px-4 py-3 text-gray-700">
                    {index + 1}
                  </td>
                  {/* Payment Details */}
                  <td className="border px-4 py-3 text-gray-700">
                    {payment.depositor}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    ${payment.amount}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    {payment.recipient}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    {payment.plan}
                  </td>
                  {/* Date */}
                  <td className="border px-4 py-3 text-gray-700">
                    {new Date(payment?.createdAt).toLocaleDateString()}{' '}
                    {/* Format the date */}
                  </td>
                  {/* Screenshot */}
                  <td className="border px-4 py-3 text-gray-700">
                    <span
                      className="text-blue-500 cursor-pointer"
                      onClick={() =>
                        openModal(
                          `${constantObj.API_URL_PROD}/unbeatabletechEa.API/images/${payment.screenshot}`
                        )
                      } // Use the payment's screenshot URL
                    >
                      View Screenshot
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Modal */}
          {isModalOpen && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
              <div
                className="bg-white p-6 rounded-lg shadow-lg relative"
                style={{
                  width: '80%',
                  maxWidth: '800px',
                  height: '90vh',
                  maxHeight: '600px',
                  overflowY: 'auto',
                }}>
                <button
                  className="text-red-500 absolute top-2 right-2"
                  onClick={closeModal}>
                  Close
                </button>

                <div className="w-full h-full flex justify-center items-center">
                  <img
                    src={modalContent}
                    alt="Screenshot"
                    className="max-w-full max-h-full object-contain"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CompletedPaymentsTable;
