import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import Logo from './logo';

export default function AboutUs() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <div
      className="relative my-16 py-16 px-8 w-full sm:w-4/5 mx-auto text-slate-900 overflow-hidden"
      id="about">
      {/* SEO with react-helmet-async */}
      <Helmet>
        <title>About Us - UnbeatableFX | Forex Trading Signals</title>
        <meta
          name="description"
          content="Learn more about UnbeatableFX, our commitment to providing top-quality Forex trading signals, and how we ensure customer success through constant innovation and a customer-first approach."
        />
        <meta
          name="keywords"
          content="About Us, Forex Trading, unbeatableFX, Forex Signals, Trading Strategies, Innovation"
        />
        <meta
          name="robots"
          content="index, follow"
        />

        {/* Open Graph for social sharing */}
        <meta
          property="og:title"
          content="About UnbeatableFX - Forex Trading Signals"
        />
        <meta
          property="og:description"
          content="Discover UnbeatableFX's mission and why we are a leading provider of Forex trading signals."
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/logo192.png"
        />
        <meta
          property="og:url"
          content="https://unbeatabletechea.com/about-us"
        />

        {/* Twitter Cards */}
        <meta
          name="twitter:card"
          content="summary_large_image"
        />
        <meta
          name="twitter:title"
          content="About Us - UnbeatableFX"
        />
        <meta
          name="twitter:description"
          content="Learn more about UnbeatableFX, our commitment to providing top-quality Forex trading signals."
        />
        <meta
          name="twitter:image"
          content="%PUBLIC_URL%/logo192.png"
        />
      </Helmet>

      {/* Background contour shape */}
      <div className="absolute inset-0 bg-gradient-to-r from-green-400 via-blue-500 to-purple-600 clip-contour -z-10 h-full w-full opacity-60 mix-blend-overlay"></div>

      {/* Dark overlay to make text more readable */}
      <div className="absolute inset-0 bg-black bg-opacity-40 -z-10"></div>

      {/* Content with Framer Motion */}
      <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={isInView ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.1, ease: 'easeOut' }}
        className="relative z-20 text-center">
        <motion.h1
          className="sm:text-2xl text-4xl font-bold flex w-full sm:flex-row flex-col items-center gap-2 justify-center text-slate-950 mb-6 font-poppins drop-shadow-lg"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={isInView ? { scale: 1, opacity: 1 } : {}}
          transition={{ delay: 0.2, duration: 1 }}>
          <p>Why Choose</p> <Logo />
        </motion.h1>
        <motion.p
          className="text-base font-light md:text-center text-left text-900 md:text-2xl leading-relaxed px-2 sm:px-12 drop-shadow-md"
          initial={{ opacity: 0, x: -50 }}
          animate={isInView ? { opacity: 1, x: 0 } : {}}
          transition={{ delay: 0.4, duration: 1 }}>
          We are committed to providing the profitable trading bot services that
          work smoothly on Trading platform such as Metatrader 4/5, Tradelocker,
          cTrader, Dxtrade, Matchtrader and the likes... in the market. Our
          diverse and dynamic team is dedicated to ensuring success through
          constant innovation and customer-first approach. Together, we strive
          to create solutions that make a real difference in the world.
        </motion.p>
      </motion.div>
    </div>
  );
}
