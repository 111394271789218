import React from 'react';

const Layout = ({ children }) => {
  return (
    <div className="flex">
    
      <div className="flex-1 p-6 bg-gray-100 min-h-screen">
        {' '}
        {/* Main content area */}
        {children} {/* Render child components here */}
      </div>
    </div>
  );
};

export default Layout;
