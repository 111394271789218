// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import constantObj from '../constant/constant';
// import { format } from 'date-fns';

// const PendingPaymentsTable = () => {
//   const [payments, setPayments] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [statusMessages, setStatusMessages] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedScreenshot, setSelectedScreenshot] = useState('');

//   useEffect(() => {
//     const fetchPayments = async () => {
//       try {
//         const token = Cookies.get('token');
//         const response = await axios.get(
//           `${constantObj.API_URL_PROD}/unbeatabletechEa.API/admin/paymentss/pending`,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         setPayments(response.data.pendingPayments);
//       } catch (error) {
//         if (error.response) {
//           if (error.response.status === 400) {
//             setStatusMessages(error.response.data.message || 'Bad Request.');
//           } else if (
//             error.response.status === 401 ||
//             error.response.data.message.includes('jwt')
//           ) {
//             setStatusMessages('Unauthorized access. Redirecting to login...');
//             setTimeout(() => {
//               window.location.href = '/admin/login';
//             }, 2000);
//           } else if (error.response.status === 404) {
//             setStatusMessages(
//               error.response.data.message || 'Payments not found.'
//             );
//           } else if (error.response.status === 500) {
//             setStatusMessages('Server error. Please try again later.');
//           } else {
//             setStatusMessages(
//               'An unexpected error occurred. Please try again.'
//             );
//           }
//         } else {
//           setStatusMessages('Network error. Please check your connection.');
//         }
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchPayments();
//   }, []);

//   const handleVerify = async (id) => {
//     setLoading(true);
//     try {
//       const token = Cookies.get('token');
//       await axios.patch(
//         `${constantObj.API_URL_PROD}/unbeatabletechEa.API/admin/payment/verifyd/${id}`,
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setPayments((prevPayments) =>
//         prevPayments.filter((payment) => payment._id !== id)
//       );
//       setStatusMessages((prevMessages) => ({
//         ...prevMessages,
//         [id]: 'Payment verified successfully!',
//       }));
//     } catch (error) {
//       if (error.response) {
//         if (error.response.status === 400) {
//           setStatusMessages(error.response.data.message || 'Bad Request.');
//         } else if (
//           error.response.status === 401 ||
//           error.response.data.message.includes('jwt')
//         ) {
//           setStatusMessages('Unauthorized access. Redirecting to login...');
//           setTimeout(() => {
//             window.location.href = '/admin/login';
//           }, 2000);
//         } else if (error.response.status === 404) {
//           setStatusMessages(
//             error.response.data.message || 'Payments not found.'
//           );
//         } else if (error.response.status === 500) {
//           setStatusMessages('Server error. Please try again later.');
//         } else {
//           setStatusMessages('An unexpected error occurred. Please try again.');
//         }
//       } else {
//         setStatusMessages('Network error. Please check your connection.');
//       }
//       // setStatusMessages((prevMessages) => ({
//       //   ...prevMessages,
//       //   [id]:
//       //     error.message ||
//       //     'Failed to verify the payment. Please try again later.',
//       // }));
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleDecline = async (id) => {
//     setLoading(true);
//     try {
//       const token = Cookies.get('token');
//       await axios.patch(
//         `${constantObj.API_URL_PROD}/unbeatabletechEa.API/admin/payment/decline/${id}`,
//         {},
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setPayments((prevPayments) =>
//         prevPayments.filter((payment) => payment._id !== id)
//       );
//       setStatusMessages((prevMessages) => ({
//         ...prevMessages,
//         [id]: 'Payment declined successfully!',
//       }));
//     } catch (error) {
//       if (error.response) {
//         if (error.response.status === 400) {
//           setStatusMessages(error.response.data.message || 'Bad Request.');
//         } else if (
//           error.response.status === 401 ||
//           error.response.data.message.includes('jwt')
//         ) {
//           setStatusMessages('Unauthorized access. Redirecting to login...');
//           setTimeout(() => {
//             window.location.href = '/admin/login';
//           }, 2000);
//         } else if (error.response.status === 404) {
//           setStatusMessages(
//             error.response.data.message || 'Payments not found.'
//           );
//         } else if (error.response.status === 500) {
//           setStatusMessages('Server error. Please try again later.');
//         } else {
//           setStatusMessages('An unexpected error occurred. Please try again.');
//         }
//       } else {
//         setStatusMessages('Network error. Please check your connection.');
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleViewScreenshot = (screenshotUrl) => {
//     setSelectedScreenshot(screenshotUrl);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedScreenshot('');
//   };

//   return (
//     <div className="overflow-x-auto">
//       {loading ? (
//         <p className="text-center text-gray-500">Loading payments...</p>
//       ) : error ? (
//         <p className="text-red-500 text-center">{error}</p>
//       ) : payments.length === 0 ? (
//         <p className="text-slate-900 text-center">
//           No pending payments to display.
//         </p>
//       ) : (
//         <>
//           <table className="min-w-full bg-gray-100 rounded-lg shadow-md">
//             <thead className="bg-gray-800">
//               <tr>
//                 <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
//                   #
//                 </th>
//                 <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
//                   Depositor
//                 </th>
//                 <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
//                   Amount
//                 </th>
//                 <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
//                   Recipient
//                 </th>
//                 <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
//                   Plan
//                 </th>
//                 <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
//                   Date
//                 </th>
//                 <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
//                   Actions
//                 </th>
//                 <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
//                   Screenshot
//                 </th>
//               </tr>
//             </thead>
//             <tbody className="bg-white">
//               {payments.map((payment, index) => (
//                 <tr
//                   key={payment._id}
//                   className="hover:bg-gray-50 transition-colors duration-200">
//                   <td className="border px-4 py-3 text-gray-700">
//                     {index + 1}
//                   </td>
//                   <td className="border px-4 py-3 text-gray-700">
//                     {payment.depositor}
//                   </td>
//                   <td className="border px-4 py-3 text-gray-700">
//                     ${payment.amount}
//                   </td>
//                   <td className="border px-4 py-3 text-gray-700">
//                     {payment.recipient}
//                   </td>
//                   <td className="border px-4 py-3 text-gray-700">
//                     {payment.plan}
//                   </td>
//                   <td className="border px-4 py-3 text-gray-700">
//                     {format(
//                       new Date(
//                         payment.createdAt ? payment.createdAt : new Date()
//                       ),
//                       'dd/MM/yyyy'
//                     )}
//                   </td>
//                   <td className="border px-4 py-3 flex space-x-2">
//                     <button
//                       className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-all duration-200"
//                       onClick={() => handleVerify(payment._id)}>
//                       Verify
//                     </button>
//                     <button
//                       className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-all duration-200"
//                       onClick={() => handleDecline(payment._id)}>
//                       Decline
//                     </button>
//                     {statusMessages[payment._id] && (
//                       <span
//                         className={`ml-2 ${
//                           statusMessages[payment._id].includes('Failed')
//                             ? 'text-red-500'
//                             : 'text-green-500'
//                         }`}>
//                         {statusMessages[payment._id]}
//                       </span>
//                     )}
//                   </td>
//                   <td className="border px-4 py-3 text-gray-700">
//                     <button
//                       className="text-blue-500 underline"
//                       onClick={() =>
//                         handleViewScreenshot(
//                           `${constantObj.API_URL_PROD}/unbeatabletechEa.API/images/${payment.screenshot}`
//                         )
//                       }>
//                       View Screenshot
//                     </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>

//           {isModalOpen && (
//             <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
//               <div
//                 className="bg-white p-4 rounded-lg relative"
//                 style={{
//                   width: '80%',
//                   maxWidth: '800px',
//                   height: '90vh',
//                   maxHeight: '600px',
//                   overflowY: 'auto',
//                 }}>
//                 <button
//                   className="absolute top-2 right-2 text-gray-500"
//                   onClick={closeModal}>
//                   Close
//                 </button>

//                 <img
//                   src={selectedScreenshot}
//                   alt="Screenshot"
//                   className="max-w-full h-auto object-contain"
//                 />
//               </div>
//             </div>
//           )}
//         </>
//       )}
//     </div>
//   );
// };

// export default PendingPaymentsTable;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import constantObj from '../constant/constant';
import { format } from 'date-fns';

const PendingPaymentsTable = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusMessages, setStatusMessages] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedScreenshot, setSelectedScreenshot] = useState('');

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const token = Cookies.get('token');
        const response = await axios.get(
          `${constantObj.API_URL_PROD}/unbeatabletechEa.API/admin/payments/pending`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPayments(response.data.pendingPayments);
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            setStatusMessages(error.response.data.message || 'Bad Request.');
          } else if (
            error.response.status === 401 ||
            error.response.data.message.includes('jwt')
          ) {
            setStatusMessages('Unauthorized access. Redirecting to login...');
            setTimeout(() => {
              window.location.href = '/admin/login';
            }, 2000);
          } else if (error.response.status === 404) {
            setStatusMessages(
              error.response.data.message || 'Payments not found.'
            );
          } else if (error.response.status === 500) {
            setStatusMessages('Server error. Please try again later.');
          } else {
            setStatusMessages(
              'An unexpected error occurred. Please try again.'
            );
          }
        } else {
          setStatusMessages('Network error. Please check your connection.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPayments();
  }, []);

  const handleVerify = async (id) => {
    setLoading(true);
    try {
      const token = Cookies.get('token');
      await axios.patch(
        `${constantObj.API_URL_PROD}/unbeatabletechEa.API/admin/payment/verify/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPayments((prevPayments) =>
        prevPayments.filter((payment) => payment._id !== id)
      );
      setStatusMessages((prevMessages) => ({
        ...prevMessages,
        [id]: 'Payment verified successfully!',
      }));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setStatusMessages(error.response.data.message || 'Bad Request.');
        } else if (
          error.response.status === 401 ||
          error.response.data.message.includes('jwt')
        ) {
          setStatusMessages('Unauthorized access. Redirecting to login...');
          setTimeout(() => {
            window.location.href = '/admin/login';
          }, 2000);
        } else if (error.response.status === 404) {
          setStatusMessages(
            error.response.data.message || 'Payments not found.'
          );
        } else if (error.response.status === 500) {
          setStatusMessages('Server error. Please try again later.');
        } else {
          setStatusMessages('An unexpected error occurred. Please try again.');
        }
      } else {
        setStatusMessages('Network error. Please check your connection.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async (id) => {
    setLoading(true);
    try {
      const token = Cookies.get('token');
      await axios.patch(
        `${constantObj.API_URL_PROD}/unbeatabletechEa.API/admin/payment/decline/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPayments((prevPayments) =>
        prevPayments.filter((payment) => payment._id !== id)
      );
      setStatusMessages((prevMessages) => ({
        ...prevMessages,
        [id]: 'Payment declined successfully!',
      }));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          setStatusMessages(error.response.data.message || 'Bad Request.');
        } else if (
          error.response.status === 401 ||
          error.response.data.message.includes('jwt')
        ) {
          setStatusMessages('Unauthorized access. Redirecting to login...');
          setTimeout(() => {
            window.location.href = '/admin/login';
          }, 2000);
        } else if (error.response.status === 404) {
          setStatusMessages(
            error.response.data.message || 'Payments not found.'
          );
        } else if (error.response.status === 500) {
          setStatusMessages('Server error. Please try again later.');
        } else {
          setStatusMessages('An unexpected error occurred. Please try again.');
        }
      } else {
        setStatusMessages('Network error. Please check your connection.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleViewScreenshot = (screenshotUrl) => {
    setSelectedScreenshot(screenshotUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedScreenshot('');
  };

  return (
    <div className="overflow-x-auto">
      {loading ? (
        <p className="text-center text-gray-500">Loading payments...</p>
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : payments.length === 0 ? (
        <p className="text-slate-900 text-center">
          No pending payments to display.
        </p>
      ) : (
        <>
          <table className="min-w-full bg-gray-100 rounded-lg shadow-md">
            <thead className="bg-gray-800">
              <tr>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  #
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Depositor
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Amount
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Recipient
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Plan
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Date
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Actions
                </th>
                <th className="py-3 px-4 text-left text-gray-100 font-semibold uppercase">
                  Screenshot
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {payments.map((payment, index) => (
                <tr
                  key={payment._id}
                  className="hover:bg-gray-50 transition-colors duration-200">
                  <td className="border px-4 py-3 text-gray-700">
                    {index + 1}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    {payment.depositor}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    ${payment.amount}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    {payment.recipient}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    {payment.plan}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    {format(
                      new Date(
                        payment.createdAt ? payment.createdAt : new Date()
                      ),
                      'dd/MM/yyyy'
                    )}
                  </td>
                  <td className="border px-4 py-3 flex space-x-2">
                    <button
                      className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-all duration-200"
                      onClick={() => handleVerify(payment._id)}>
                      Verify
                    </button>
                    <button
                      className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-all duration-200"
                      onClick={() => handleDecline(payment._id)}>
                      Decline
                    </button>
                    {statusMessages[payment._id] && (
                      <span
                        className={`ml-2 ${
                          statusMessages[payment._id].includes('Failed')
                            ? 'text-red-500'
                            : 'text-green-500'
                        }`}>
                        {statusMessages[payment._id]}
                      </span>
                    )}
                  </td>
                  <td className="border px-4 py-3 text-gray-700">
                    <button
                      className="text-blue-500 underline"
                      onClick={() =>
                        handleViewScreenshot(
                          `${constantObj.API_URL_PROD}/unbeatabletechEa.API/images/${payment.screenshot}`
                        )
                      }>
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Modal for Viewing Screenshot */}
          {isModalOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto">
                <img
                  src={selectedScreenshot}
                  alt="Screenshot"
                  className="w-full h-auto"
                />
                <button
                  className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-all duration-200"
                  onClick={closeModal}>
                  Close
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PendingPaymentsTable;
