import { useState } from 'react';
import {
  FaListAlt,
  FaCheckCircle,
  FaTimesCircle,
  FaSignOutAlt,
} from 'react-icons/fa';
import PendingPaymentsTable from '../components/pendingPay';
import CompletedPaymentsTable from '../components/completed';
import DeclinedPaymentsTable from '../components/declined';
import Cookies from 'js-cookie';

const PaymentsDashboard = () => {
  const [activeTab, setActiveTab] = useState('pending');

  function logout() {
    Cookies.remove('token');
    window.location.href = '/admin/login';
  }
  return (
    <>
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 py-4 w-full bg-white shadow-md z-50 flex justify-between items-center px-6">
        <h1 className="text-2xl text-slate-950 font-bold">Control Panel</h1>
        <button
          className="text-red-600 flex items-center"
          onClick={() => logout()}>
          <FaSignOutAlt className="mr-1" />
          Logout
        </button>
      </header>

      {/* Main content with padding to avoid overlap */}
      <div className="flex flex-col h-screen pt-16 font-['Poppins', 'Roboto']">
        {/* Tab Navigation */}
        <nav className="bg-gray-100 p-2 my-6 hidden md:flex fixed top-16 left-0 right-0 z-50">
          {' '}
          {/* Fixed position at the top */}
          <ul className="flex justify-around">
            <li>
              <button
                className={`py-2 px-4 rounded-lg transition duration-300 ${
                  activeTab === 'pending'
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setActiveTab('pending')}>
                Pending
              </button>
            </li>
            <li>
              <button
                className={`py-2 px-4 rounded-lg transition duration-300 ${
                  activeTab === 'completed'
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setActiveTab('completed')}>
                Completed
              </button>
            </li>
            <li>
              <button
                className={`py-2 px-4 rounded-lg transition duration-300 ${
                  activeTab === 'declined'
                    ? 'bg-blue-600 text-white'
                    : 'text-gray-600 hover:bg-gray-200'
                }`}
                onClick={() => setActiveTab('declined')}>
                Declined
              </button>
            </li>
          </ul>
        </nav>

        {/* Main content area with scrollable tables */}
        <div className="flex-1 overflow-y-auto mt-11 z-0 overflow-x-scroll p-4 md:p-6">
          {activeTab === 'pending' && (
            <section>
              <h2 className="text-xl font-semibold mt-6 mb-4 text-slate-950 font-montserrat">
                Pending Payments
              </h2>
              <div className="overflow-x-auto">
                <PendingPaymentsTable />
              </div>
            </section>
          )}
          {activeTab === 'completed' && (
            <section>
              <h2 className="text-xl mt-6 font-semibold mb-4 font-montserrat text-slate-950">
                Completed Payments
              </h2>
              <div className="overflow-x-auto">
                <CompletedPaymentsTable />
              </div>
            </section>
          )}
          {activeTab === 'declined' && (
            <section>
              <h2 className="text-xl mt-6 font-semibold text-slate-950 mb-4 font-montserrat">
                Declined Payments
              </h2>
              <div className="overflow-x-auto">
                <DeclinedPaymentsTable />
              </div>
            </section>
          )}
        </div>
      </div>

      {/* Mobile Bottom Toggle with Icons (fixed at the bottom) */}
      <div className="md:hidden fixed bottom-0 left-0 right-0 bg-white flex justify-around p-2 border-t shadow-lg z-50">
        <button
          className={`flex-col justify-center gap-2 items-center text-center ${
            activeTab === 'pending' ? 'text-blue-600' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('pending')}>
          <FaListAlt
            className="mx-auto"
            size={24}
          />
          <p className="text-xs mt-2">Pending</p>
        </button>
        <button
          className={`flex-col justify-center items-center text-center ${
            activeTab === 'completed' ? 'text-blue-600' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('completed')}>
          <FaCheckCircle
            className="mx-auto"
            size={24}
          />
          <p className="text-xs mt-2">Completed</p>
        </button>
        <button
          className={`flex-col justify-center items-center text-center ${
            activeTab === 'declined' ? 'text-blue-600' : 'text-gray-500'
          }`}
          onClick={() => setActiveTab('declined')}>
          <FaTimesCircle
            className="mx-auto"
            size={24}
          />
          <p className="text-xs mt-2">Declined</p>
        </button>
      </div>
    </>
  );
};

export default PaymentsDashboard;
