import React, { useState, useEffect } from 'react';
import { FaCheck, FaCopy } from 'react-icons/fa';
import { motion } from 'framer-motion';
import axios from 'axios';
import Cookies from 'js-cookie';
import constantObj from '../constant/constant';
import AttentionMessage from './indicator';

export default function PricingSection() {
  // Modal States
  const [isStep1ModalOpen, setIsStep1ModalOpen] = useState(false);
  const [isStep2ModalOpen, setIsStep2ModalOpen] = useState(false);
  const [isCountdownModalOpen, setIsCountdownModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  // Payment States
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [depositorAddress, setDepositorAddress] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const [screenshotPreview, setScreenshotPreview] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [countdown, setCountdown] = useState(600); // 10 minutes in seconds
  const [adminConfirmed, setAdminConfirmed] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');

  // UI States
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState(''); // 'success' or 'error'
  const [errorMessage, setErrorMessage] = useState('');

  // Recipient Wallet Address (Service Provider)
  const walletAddressArr = [
    { name: 'BTC', address: constantObj.BTC_WALLET },
    { name: 'USDT', address: constantObj.USDT_WALLET },
  ];

  // Pricing Plans
  const pricingPlans = [
    {
      title: 'BASIC PACKAGE ',
      price: 145.99,

      features: [
        'Trial Version ',
        '15 - 20% Win Rate',
        '24/5 Support Guarantee',
      ],
    },
    {
      title: 'ADVANCE PACKAGE',
      price: 499.59,

      features: ['Renting Version ', '45 - 60% Win Rate', 'Secure & Reliable'],
    },
    {
      title: 'VIP PACKAGE',
      price: 999.95,

      features: [
        'Unlimited License ',
        '95% Winning Rate ',
        'Access to All Markets',
      ],
    },
  ];

  // Animation Variants for Cards
  const cardAnimation = {
    hidden: { opacity: 0, scale: 0.95 },
    visible: { opacity: 1, scale: 1 },
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };

  // Open Step 1 Modal
  const openStep1Modal = (plan) => {
    setSelectedPlan(plan);
    setIsStep1ModalOpen(true);
  };

  // Close Step 1 Modal
  const closeStep1Modal = () => {
    setIsStep1ModalOpen(false);
  };

  // Open Step 2 Modal
  const openStep2Modal = () => {
    setIsStep1ModalOpen(false);
    setIsStep2ModalOpen(true);
  };

  // Close Step 2 Modal
  const closeStep2Modal = () => {
    setIsStep2ModalOpen(false);
  };

  // Handle Copy to Clipboard
  const copyToClipboard = (wallet) => {
    navigator.clipboard.writeText(wallet).then(
      () => {
        setWalletAddress(wallet);
        setStatusMessage('Recipient address copied to clipboard!');
        setStatusType('success');
        setIsStatusModalOpen(true);
      },
      () => {
        setStatusMessage('Failed to copy address.');
        setStatusType('error');
        setIsStatusModalOpen(true);
      }
    );
  };

  // Handle Screenshot Change
  const handleScreenshotChange = (e) => {
    const file = e.target.files[0];
    setScreenshot(file);
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setScreenshotPreview(previewUrl);
    }
  };

  // Handle Screenshot Upload and Payment Submission
  const handleSubmitPayment = async () => {
    if (!screenshot || !depositorAddress || !selectedPlan) {
      setErrorMessage('Please provide all required information.');
      return;
    }

    setLoading(true);
    const token = Cookies.get('token'); // Ensure token exists and is valid
    const formData = new FormData();
    formData.append('amount', selectedPlan.price);
    formData.append('plan', selectedPlan.title);
    formData.append('recipient', walletAddress);
    formData.append('depositor', depositorAddress);
    formData.append('screenshot', screenshot);

    try {
      const response = await axios.post(
        `${constantObj.API_URL_PROD}/unbeatabletechEa.API/admin/payment`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response);
      if (response.status === 201) {
        setPaymentId(response.data.payment._id);
        setIsCountdownModalOpen(true);
        setIsStep2ModalOpen(false);
        setStatusMessage('Payment submitted. Awaiting confirmation.');
        setStatusType('success');
      }
    } catch (error) {
      console.log('Error uploading screenshot:', error.response);
      setErrorMessage('Failed to submit payment. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Polling for Payment Confirmation

  // Countdown Effect
  useEffect(() => {
    const checkPaymentConfirmation = async () => {
      if (!paymentId) return;
      console.log(paymentId);
      try {
        const response = await axios.get(
          `${constantObj.API_URL_PROD}/unbeatabletechEa.API/admin/payment/confirm/${paymentId}`
        );
        console.log(response);
        if (
          response.data.status === 'success' &&
          response.data.payment.isVerified
        ) {
          setAdminConfirmed(true);
          setStatusMessage('Payment confirmed by admin!');
          setStatusType('success');
          setIsCountdownModalOpen(false);
          setIsStatusModalOpen(true);
        }
      } catch (error) {
        console.log('Error confirming payment:', error.response);
        // Optionally handle specific errors
      }
    };

    let intervalId;

    if (isCountdownModalOpen && countdown > 0 && !adminConfirmed) {
      intervalId = setInterval(() => {
        checkPaymentConfirmation();
        setCountdown((prev) => prev - 1);
      }, 10000); // Poll every 10 seconds

      return () => clearInterval(intervalId);
    }

    if (countdown === 0 && !adminConfirmed) {
      setIsCountdownModalOpen(false);
      setStatusMessage(
        'Payment confirmation timed out. Please contact support.'
      );
      setStatusType('error');
      setIsStatusModalOpen(true);
    }

    return () => clearInterval(intervalId);
  }, [countdown, isCountdownModalOpen, adminConfirmed, paymentId]);

  // Format Countdown Time
  const formatCountdown = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // Close Status Modal
  const closeStatusModal = () => {
    setIsStatusModalOpen(false);
    setStatusMessage('');
    setStatusType('');
  };

  return (
    <section
      id="pricing"
      className="bg-gradient-to-r from-black to-gray-800 py-12 px-6 text-white"
      aria-label="Pricing Plans Section">
      {/* Alert Messages */}
      {errorMessage && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert">
          <strong className="font-bold">Error! </strong>
          <span className="block sm:inline">{errorMessage}</span>

          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer"
            onClick={() => setErrorMessage('')}>
            <svg
              className="fill-current h-6 w-6 text-red-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20">
              <path d="M14.348 14.849a1 1 0 01-1.414 0L10 11.414l-2.934 2.935a1 1 0 01-1.414-1.415l2.935-2.934-2.935-2.934a1 1 0 011.414-1.415L10 8.586l2.934-2.935a1 1 0 111.414 1.415L11.414 10l2.935 2.934a1 1 0 010 1.415z" />
            </svg>
          </span>
        </div>
      )}

      {/* Pricing Cards */}
      <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {pricingPlans.map((plan, index) => (
          <motion.div
            key={index}
            className="bg-gray-900 rounded-lg shadow-lg p-6 flex flex-col justify-between"
            variants={cardAnimation}
            initial="hidden"
            animate="visible"
            whileHover="hover">
            <div>
              <h3 className="text-xl font-semibold mb-4">{plan.title}</h3>
              <p className="text-4xl font-bold mb-2">${plan.price}</p>
              <p className="text-sm text-gray-400 mb-6">{plan.duration}</p>
              <ul className="mb-6">
                {plan.features.map((feature, idx) => (
                  <li
                    key={idx}
                    className="flex items-center mb-2">
                    <FaCheck className="text-green-500 mr-2" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-auto"
              onClick={() => openStep1Modal(plan)}>
              Select Plan
            </button>
          </motion.div>
        ))}
        <AttentionMessage
          message={
            'We Have Rooms For All Budgets,  Contact Admin For More Details...'
          }
        />
      </div>

      {/* Step 1 Modal: Show Recipient Address */}
      {isStep1ModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white text-black rounded-lg p-8 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Payment Instructions</h2>
            <p className="mb-4">
              Please send your payment to the following recipient address:
            </p>
            {walletAddressArr.map((wallet, index) => (
              <div
                key={index}
                className="flex items-center bg-gray-200 p-3 rounded mb-6">
                <div>
                  <p className="font-bold uppercase font-montserrat">
                    {wallet.name}
                  </p>
                  <span className="flex-1 break-all">{wallet.address}</span>
                </div>
                <button
                  onClick={() => copyToClipboard(wallet.address)}
                  className="ml-4 bg-blue-500 hover:bg-blue-600 text-white p-2 rounded"
                  title="Copy Address">
                  <FaCopy />
                </button>
              </div>
            ))}

            <div className="flex justify-end">
              <button
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={closeStep1Modal}>
                Cancel
              </button>
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={openStep2Modal}>
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Step 2 Modal: Upload Screenshot and Enter Depositor Address */}
      {isStep2ModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white text-black rounded-lg p-8 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Submit Your Payment</h2>
            <div className="mb-4">
              <label
                htmlFor="depositorAddress"
                className="block mb-2">
                Depositor Wallet Address
              </label>
              <input
                type="text"
                id="depositorAddress"
                className="border border-gray-300 p-2 w-full rounded"
                placeholder="Enter your wallet address"
                value={depositorAddress}
                onChange={(e) => setDepositorAddress(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="screenshot"
                className="block mb-2">
                Upload Payment Screenshot
              </label>
              <input
                type="file"
                id="screenshot"
                accept="image/*"
                className="border border-gray-300 p-2 w-full rounded"
                onChange={handleScreenshotChange}
              />
              {screenshotPreview && (
                <img
                  src={screenshotPreview}
                  alt="Screenshot Preview"
                  className="mt-4 w-full h-auto rounded"
                />
              )}
            </div>
            <div className="flex justify-end">
              <button
                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={closeStep2Modal}>
                Cancel
              </button>
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleSubmitPayment}
                disabled={loading}>
                {loading ? 'Submitting...' : 'Submit Payment'}
              </button>
            </div>
            {errorMessage && (
              <p className="text-red-500 mt-4">{errorMessage}</p>
            )}
          </div>
        </div>
      )}

      {/* Countdown Modal */}
      {isCountdownModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white text-black rounded-lg p-8 w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">
              Awaiting Payment Confirmation
            </h2>
            <p className="mb-4">
              Your payment is being processed. Please wait...
            </p>
            <div className="flex items-center mb-4">
              <span className="font-semibold mr-2">Time Remaining:</span>
              <span className="text-xl">{formatCountdown(countdown)}</span>
            </div>
            <div className="flex justify-end">
              <button
                className="bg-red-600 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setIsCountdownModalOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Status Modal */}
      {isStatusModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div
            className={`bg-white text-black rounded-lg p-8 w-full max-w-md ${
              statusType === 'success'
                ? 'border-4 border-green-500'
                : 'border-4 border-red-500'
            }`}>
            <h2 className="text-2xl font-bold mb-4">
              {statusType === 'success' ? 'Success' : 'Failure'}
            </h2>
            <p className="mb-6">{statusMessage}</p>
            <a
              className="text-blue-700 underline"
              href={constantObj.ADMIN}>
              Contact Admin
            </a>
            <div className="flex justify-end">
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={closeStatusModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
