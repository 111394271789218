import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { motion } from 'framer-motion';

export default function CustomerReviews() {
  const reviews = [
    {
      name: 'Mack Jr.',
      review:
        'The execution speed is phenomenal, and I have never experienced slippage! Highly recommend to all Forex traders.',
      rating: 5,
      image: './asset/review/men.jpg',
    },
    {
      name: 'Sarah L.',
      review:
        'Spreads are unbeatable, and customer support is top-notch. A reliable platform for all my trading needs.',
      rating: 5,
      image: './asset/review/w-0.jpg',
    },
    {
      name: 'Mark T.',
      review:
        'This platform has changed my trading game completely! Lightning-fast orders and excellent liquidity!',
      rating: 4.5,
      image: './asset/review/m-1.jpg',
    },
    {
      name: 'Anna P.',
      review:
        'Exceptional service, quick withdrawals, and always reliable. Best trading platform I have used so far.',
      rating: 4.5,
      image: './asset/review/w-1.jpg',
    },
    {
      name: 'David B.',
      review:
        'I’ve been trading for over 10 years, and this platform is the most user-friendly I’ve encountered. Excellent.',
      rating: 5,
      image: './asset/review/m-2.jpg',
    },
    {
      name: 'Lisa M.',
      review:
        'Very secure and reliable. I’ve never felt more confident in trading since I started using this platform.',
      rating: 5,
      image: './asset/review/w-2.jpg',
    },
    {
      name: 'James C.',
      review:
        'The customer service is phenomenal! They answered all my queries and helped me with account setup.',
      rating: 4.5,
      image: './asset/review/m-3.jpg',
    },
    {
      name: 'Emily R.',
      review:
        'Great platform with amazing tools for charting and analysis. Trading has never been easier.',
      rating: 5,
      image: './asset/review/w-3.jpg',
    },
    {
      name: 'Michael F.',
      review:
        'Their educational resources helped me get started, and now I’m consistently profitable. Couldn’t be happier!',
      rating: 5,
      image: './asset/review/m-4.jpg',
    },
    {
      name: 'Olivia W.',
      review:
        'Fantastic platform for both beginners and experienced traders. I’ve seen my trading improve significantly.',
      rating: 4.5,
      image: './asset/review/w-4.jpg',
    },
    {
      name: 'Richard K.',
      review:
        'Easy-to-use platform with real-time data and fast execution. Great experience so far!',
      rating: 4,
      image: './asset/review/m-5.jpg',
    },
    {
      name: 'Sophia J.',
      review:
        'Highly recommended for anyone serious about trading. The tools and insights available are incredible.',
      rating: 5,
      image: './asset/review/w-5.jpg',
    },
  ];

  return (
    <section
      className="w-full relative bg-cover  mx-auto bg-center py-16 px-6 text-white"
      style={{
        backgroundImage: `url('./asset/hero.jpg')`, // Placeholder background image URL
      }}>
      {/* Background overlay with gradient */}
      <div className="absolute inset-0 bg-gradient-to-t from-black via-gray-800 to-transparent opacity-90 z-0"></div>

      {/* Section Title */}
      <motion.div
        className="text-center mb-12 z-10 relative"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3, ease: 'easeOut' }}>
        <h2 className="text-4xl font-bold text-green-500 mb-4">
          What Our Customers Say
        </h2>
        <p className="text-xl text-gray-300">
          Hear from some of our happy clients about their experience with us.
        </p>
      </motion.div>

      {/* Swiper for Review Cards */}
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={20}
        slidesPerView={1}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
        navigation
        className="mySwiper z-10 relative">
        {reviews.map((review, index) => (
          <SwiperSlide key={index}>
            <motion.div
              className="bg-gray-800 bg-opacity-90 rounded-xl shadow-lg p-6 text-left flex-shrink-0"
              initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, ease: 'easeOut' }}>
              <div className="flex items-center gap-4 mb-4">
                <img
                  src={review.image}
                  alt={review.name}
                  className="w-16 h-16 rounded-full object-cover border-2 border-green-500"
                />
                <div>
                  <p className="text-xl font-semibold text-green-400">
                    {review.name}
                  </p>
                  <div className="flex items-center mt-2">
                    {[...Array(Math.floor(review.rating))].map((_, i) => (
                      <span
                        key={i}
                        className="text-green-400 text-lg">
                        ★
                      </span>
                    ))}
                    {review.rating % 1 !== 0 && (
                      <span className="text-green-400 text-lg">☆</span>
                    )}
                  </div>
                </div>
              </div>
              <p className="text-gray-300">{review.review}</p>
            </motion.div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom CSS for smaller navigation arrows */}
      <style jsx>{`
        .swiper-button-next,
        .swiper-button-prev {
          width: 25px; /* Reduced width */
          height: 25px; /* Reduced height */
          color: white;
        }

        .swiper-button-next:after,
        .swiper-button-prev:after {
          font-size: 18px; /* Adjust arrow icon size */
        }
      `}</style>
    </section>
  );
}
