import React, { useState, useEffect } from 'react';
import './App.css';
import './index.css';
import HomePage from './pages/home';
import Login from './components/login';
import PaymentsDashboard from './pages/dashboardpage';
// The new admin dashboard
import CookieConsent from './components/cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout';
import NotFound from './components/notfound';

function App() {
  const [loading, setLoading] = useState(true);
  const [displayText, setDisplayText] = useState('');

  const loadingMessage =
    '     Welcome to UnbeatableFx Robot... Your ultimate destination for Forex trading signals.';

  useEffect(() => {
    let index = 0;
    const typingInterval = setInterval(() => {
      if (index < loadingMessage.length) {
        setDisplayText((prev) => prev + loadingMessage.charAt(index));
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 50); // Typing speed

    const loadingTimeout = setTimeout(() => {
      setLoading(false);
      clearInterval(typingInterval);
    }, 4000);

    return () => {
      clearInterval(typingInterval);
      clearTimeout(loadingTimeout);
    };
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <div className="App bg-gray-50 text-slate-50 font-poppins min-h-dvh">
          <Helmet>
            <title> UnbeatableFx Robot - Best Forex Trading Signals</title>
            <meta
              name="description"
              content="Join UnbeatableFx Robot for expert and best Forex trading signals, live accounts, props accounts, and real-time strategies via our Telegram channel."
            />
            <meta
              name="keywords"
              content="Best Forex trading, Forex signals, live accounts, props account, UnbeatableFx Robot, Forex analysis, Trading Automation"
            />
            <meta
              name="author"
              content="UnbeatableFx Robot"
            />
            <meta
              name="robots"
              content="index, follow"
            />
            <meta
              property="og:title"
              content="UnbeatableFx Robot | Best Forex Trading Signals | Robot Trading automation"
            />
            <meta
              property="og:description"
              content="Get the best Forex signals and strategies from UnbeatableFx Robot via our Telegram channel."
            />
            <meta
              property="og:image"
              content="./logo.png"
            />
            <meta
              property="og:url"
              content="https://unbeatabletechea.com"
            />
            <meta
              property="og:type"
              content="website"
            />
            <meta
              name="twitter:card"
              content="summary_large_image"
            />
            <meta
              name="twitter:title"
              content="UnbeatableFx Robot - Best Forex Trading Signals"
            />
            <meta
              name="twitter:description"
              content="Join UnbeatableFx Robot for expert Forex trading signals."
            />
            <meta
              name="twitter:image"
              content="%PUBLIC_URL%/logo.png"
            />
          </Helmet>

          {/* Loading Screen */}
          {loading ? (
            <div className="flex absolute left-0 top-0 right-0 bottom-0 items-center justify-center min-h-screen">
              <h1 className="text-2xl w-dvw px-6 h-dvh flex flex-col justify-center items-center mx-auto bg-slate-950 text-center text-slate-50">
                {displayText}
              </h1>
            </div>
          ) : (
            <Routes>
              {/* Home Page */}
              <Route
                path="/"
                element={<HomePage />}
              />

              {/* Admin Login */}
              <Route
                path="/admin/login"
                element={<Login />}
              />

              {/* Payments Dashboard as the main admin dashboard */}
              <Route
                path="/admin/dashboard"
                element={
                  <Layout>
                    <PaymentsDashboard />
                  </Layout>
                }
              />
              <Route
                path="*"
                element={<NotFound />}
              />
            </Routes>
          )}
        </div>
      </Router>

      {/* Cookie Consent */}
      <CookieConsent />
    </HelmetProvider>
  );
}

export default App;
